<template lang="html">
	<div id="page-backoffice">
		<Sidebar/>
		<router-view v-slot="{ Component }" @toast="pushToast">
			<transition name="change-page" mode="out-in">
				<component :is="Component" />
			</transition>
		</router-view>
		<transition-group class="toast-zone" name="list" tag="div">
			<Toast class="toasts-item" v-for="toast in toasts" :data="toast" @click="deleteToast(toast.id)" v-bind:key="toast"/>
		</transition-group>
	</div>
</template>

<script>
import Sidebar from "@/components/Sidebar"

export default {
	components: {
		Sidebar
	},
	data () {
		return {
			toasts: [
			]
		};
	},
	methods: {
		pushToast(toast) {
			toast.id = Date.now().toString();
			this.toasts.push(toast);
			setTimeout((scope) => {
				scope.deleteToast(toast.id);
			}, 10000, this);
		},
		findToastIndex(toastId) {
			for (var toast of this.toasts) {
				if (toast.id === toastId) {
					return	this.toasts.indexOf(toast);
				}
			}
		},
		deleteToast(toastId) {
			var index = this.findToastIndex(toastId);
			if (!isNaN(index)) {
				this.toasts.splice(index, 1);
			}
		}
	},
	mounted() {

	}
}
</script>

<style lang="scss" scoped>

.list-enter-active {
  animation: list-in .3s;
}
.list-leave-active {
  animation: list-out .2s;
}
.list-move {
  transition: transform 1s;
}


@keyframes list-in {
  0% {
    opacity: 0;
	transform: translateX(5px);
  }
  20% {
	transform: translateX(-2px);
  }
  100% {
    opacity: 1;
	transform: translateX(0px);
  }
}

@keyframes list-out {
  0% {
    opacity: 1;
	transform: translateX(0px);
  }
  100% {
    opacity: 0;
	transform: translateX(5px);
  }
}

#page-backoffice
{
	position: relative;
	display: flex;
	height: 100vh;
	width: 100vw;

	background-image: $PTN_TOPO;
	z-index: 0;

	&::before
	{
		content: "";
		position: absolute;
		height: 100%;
		width: 100%;
		background: linear-gradient(180deg, rgba(22,22,22,0) 0%, rgba(22,22,22,0) 25%, $BG_MAIN 50%, $BG_MAIN 100%);;
		z-index: -1
	}


	& > *
	{
		height: 100%;
	}

	.toast-zone
	{
		position: absolute;
		z-index: 500;
		right: 0;
		width: 384px;

		display: flex;
		flex-direction: column-reverse;
		box-sizing: border-box;
		padding: 16px;
	}
}
.change-page-enter-active {
  animation: change-page .3s;
}
.change-page-leave-active {
  animation: change-page .15s reverse;
}

@keyframes change-page {
  0% {
    opacity: 0;
	transform: translateX(5px);
  }
  100% {
    opacity: 1;
	transform: translateX(0px);
  }
}
</style>
