import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import Cookies from 'universal-cookie'
import sha256 from "crypto-js/sha256"

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCog } from '@fortawesome/pro-solid-svg-icons'
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { faHome } from '@fortawesome/pro-solid-svg-icons'
import { faQuestion } from '@fortawesome/pro-solid-svg-icons'
import { faTable } from '@fortawesome/pro-regular-svg-icons'
import { faFileImport } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


const requireComps = require.context(
    './components/ui',
    true,
    /.*\.(vue|js)$/
);

library.add(faCog);
library.add(faHome);
library.add(faTable);
library.add(faFileImport);
library.add(faQuestion);
library.add(faCaretRight);
library.add(faCheck);

var app = createApp(App)
var cookies =  new Cookies();
app.component("fa-icon", FontAwesomeIcon)

// app.config.globalProperties.$geojson = "http://localhost:3007"


if (process.env.NODE_ENV === "dev" || process.env.NODE_ENV === "development" ) {
    app.config.globalProperties.$geojson = process.env.GEOJSON_API_URL || "https://dev.seinemaritimenumerique.smnu.production-webadn.com:3007";
}
else {
    app.config.globalProperties.$geojson = process.env.GEOJSON_API_URL ||"https://prod.seinemaritimenumerique.smnu.production-webadn.com:42421";
}

app.config.globalProperties.$cookies = cookies;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$env = process.env.NODE_ENV;

// Chargement des components UI globalement sur tout le projet
requireComps.keys().forEach((item) => {
    const compConfig = requireComps(item);
    const compName = item
        .split("/")
        .pop()
        .replace(/\.(vue|js)/, "");

    app.component(
        compName,
        compConfig.default || compConfig
    )
});


/**
* Retourne une route correspondant au nom donné
* @author Charles Stieffenhofer <c.stieffenhofer@web-adn.com>
*/
router.getRouteByName = function(routeName) {
    for (var r of router.getRoutes()) {
        if (r.name === routeName) {
            return r;
        }
    }
    //default case: return an empty object
    return {};
}

router.cookies = cookies;
router.sha256 = sha256;
app.use(router);

app.mount('#app');
