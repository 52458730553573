<template lang="html">
	<div ref="dropdown" class="ui ui-dropdown" :class="ddClasses">
		<div class="dd-bar" @click="toggleDropdown">
			<div class="selector">
				<slot name="placeholder"></slot>
			</div>
			<div class="icon">
				<!-- PLACEHOLDER - TO CHANGE WITH FONTAWESOME -->
				▼
			</div>
		</div>
		<div class="dd-items">
			<span class="dd-item">Itemmsdklfgjlsdkfjsldkfjsldkfjsdlkfjedslk</span>
			<span class="dd-item">Item</span>
			<span class="dd-item">Item</span>
			<span class="dd-item">Item</span>
			<span class="dd-item">Item</span>
			<span class="dd-item">Item</span>
		</div>
	</div>
</template>

<script>
import { ref, onMounted, watch } from 'vue'
import { onClickOutside } from '@vueuse/core'

export default
{
	name: "Dropdown",
	props: {
		color: {
			type: String,
			default: "white"
		},
		type: {
			type: String,
			default: "blob"
		}
	},
	setup (props) {
		//data
		const ddClasses = ref({});
		const isDropdownOpen = ref(false);
		const dropdown = ref(null);

		//methods
		const toggleDropdown = async () => {
			isDropdownOpen.value = !isDropdownOpen.value;
		}

		const setupClasses = async () => {
			ddClasses.value[`color-${props.color}`] = true;
			ddClasses.value[`type-${props.type}`] = true;
			ddClasses.value[`closed`] = true;
		}

		//events
		onClickOutside(dropdown, (event) => {
			isDropdownOpen.value = false;
		})

		onMounted(setupClasses);

		//watch
		watch(isDropdownOpen, (newValue) => {
			ddClasses.value[`closed`] = !newValue;
		})


		return {
			isDropdownOpen,
			toggleDropdown,
			dropdown,
			ddClasses
		}
	}
}

</script>

<style lang="scss" scoped>
.ui-dropdown
{
	position: relative;
	min-width: 320px;
	width: 320px;



	.dd-bar
	{
		display: inline-flex;
		background-color: white;
		color: $BG_MAIN;
		border-radius: 8px;
		position: relative;

		justify-content: space-between;
		cursor: pointer;
		user-select: none;
		width: 100%;
		overflow: hidden;
		z-index: 200;

		transition: all 0.1s ease;

		.selector
		{
			transition: all 0.1s ease;
			padding: 8px 16px;
			background-color: white;
			flex-grow: 1;
			border-radius: 4px;
			color: $BG_MAIN;
		}
		.icon
		{
			padding: 8px 12px;
		}


	}

	.dd-items
	{
		position: absolute;
		top: 28px;
		padding-top: 10px;
		background-color: white;
		color: $BG_MAIN;
		width: 100%;
		z-index: 1;

		display: flex;
		flex-direction: column;

		border-radius: 0 0 8px 8px;
		padding: 8px;
		box-sizing: border-box;

		.dd-item
		{
			cursor: pointer;
			padding: 12px ;
			border-radius: 4px;

			&:hover
			{
				background: $FG_MAIN;
			}
		}
	}

	&.closed
	{
		.dd-bar
		{
			background-color: $FG_MAIN;
			.selector
			{
				background-color: $FG_MAIN;
			}
			&:hover
			{
				background-color: white;
				.selector
				{
					background-color: white;
				}
			}
		}


		.dd-items
		{
			display: none;
		}
	}

	&.color-
	{
		&blue
		{
			.dd-bar
			{
				border: 2px solid $AC_BLUE;
				background-color: $AC_BLUE;
				color: $FG_MAIN;
			}
		}
	}

	&.type-
	{
		&floating
		{
			display: inline-block;
			width: auto;
			min-width: auto;

			.dd-items
			{
				min-width: 100%;
				width: auto;
				top: 44px;
				border-radius: 8px;
			}
		}
	}
}
</style>
