<template>
    <div id="page-404">
        <div class="login-form">
            <div class="side-image">
                <img src="https://i.kym-cdn.com/photos/images/newsfeed/001/697/667/95f.gif">
            </div>
            <div class="form-content">
                <div class="header">
                    <h1>404 - Oups !</h1>
                    <h2 class="subtitle">Hola voyageur, vous semblez être perdu.</h2>
                </div>

                <div class="form-fields">
                    <p>La page que vous cherchez n'a pas l'air d'exister. Voici quelques options pour vous y retrouver.</p>
                </div>

                <div class="actions">
                    <Button color="wa-blue" @click.native="$router.push({name: 'Dashboard'})">
                        Acceuil
                    </Button>
                    <Button color="wa-blue" @click.native="$router.push({name: 'Login'})">
                        Page de connexion
                    </Button>
                </div>

            </div>

    	</div>
    </div>
</template>

<script>
// @ is an alias to /src

import sha256 from 'crypto-js/sha256';

export default {
    name: '404'
}
</script>

<style lang="scss" scoped="">
#page-404
{
    background-color: #161616;
    background-image: $PTN_TOPO;

    display: flex;
    justify-content: center;
    align-items: center;

    .login-form
    {
        background-color: $BG_SECONDARY;
        width: 50%;
        height: 50%;

		max-width: 800px;
		max-height: 384px;
        overflow: hidden;

        box-shadow: $SHD_DEFAULT;

        border-radius: 16px;
		min-width: 342px;

        display: flex;

        .form-content
        {
            padding: 32px;
            display: flex;
            flex-direction: column;


            .header
            {
                h1
                {
                    margin: 0;
                    font-size: 32px;
                }

                h2.subtitle
                {
                    font-size: 18px;
                    font-weight: 100;
                    margin: 0;
                }
            }

            .form-fields
            {
                flex-grow: 1;
				display: flex;
				flex-direction: column;
				justify-content: center;
            }

            .actions
            {
                display: flex;
                flex-direction: row-reverse;

                & > * + *
                {
                    margin-right: 16px;
                }
            }
        }

        .side-image
        {
            position: relative;

            flex-grow: 1;
            width: 30%;
            max-width: 256px;
            min-width: 256px;
            height: 100%;
            background-color: $WA_YELLOW;

            display: flex;
            justify-content: center;
            align-items: center;

            overflow: hidden;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }

        }
    }
}

@media (min-width: 1px) and (max-width: 1200px) {

	.side-image
	{
		display: none !important;
	}

}
</style>
