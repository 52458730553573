<template lang="html">
	<div class="page" id="page-etl">
		<PageHeader :title="$route.name"/>

		<div class="content">
			<input type="file" name="excel-file" id="excel-file" ref="excel-file" style="display:none;" @change="getExcelViaInput">
			<label for="excel-file" class="dropzone-container">
				<div class="dropzone" ref="dropzone" @drop="getExcelViaDrop">
					<div class="dropzone-content loading" v-if="isExcelFileUploading">
						<fa-icon class="label loading-icon" icon="cog" spin />
						<p class="label"> Upload en cours ...</p>
					</div>
					<div class="dropzone-content" v-else>
						<p class="label">Déposez votre fichier Excel ici</p>
						<p class="label small">ou cliquez pour selectionner votre fichier</p>
					</div>

				</div>
			</label>
			<div class="informations">
				<span class="title">
					Informations
				</span>
				<p class="info-content">
					Pour que votre fichier Excel soit bien accepté, veuillez faire attention a ce que :
					<ul>
						<li>Il soit bien enregistré au format XLSX</li>
						<li>Les informations relatives au plaques se trouvent sur une feuille nommée Base</li>
					</ul>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from "@/components/ui/prefabs/PageHeader";
import axios from 'axios';
export default {
	data () {
		return {
			isExcelFileUploading: false
		};
	},
	components:
	{
		PageHeader
	},
	mounted() {
		setTimeout(this.disableEvents, 300);
	},
	methods:
	{
		getExcelViaInput()
        {
		   	this.isExcelFileUploading = true;
            const file1 = this.$refs["excel-file"].files[0];
			this.uploadExcel(file1);
        },
        getExcelViaDrop(e)
        {
			this.isExcelFileUploading = true;

            const file1 = e.dataTransfer.files[0];
			this.uploadExcel(file1);
        },
		disableEvents() {
			['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach( function( evt ) {
				// this.$refs["dropzone"].forEach((dropzone) => {
				// 	dropzone.addEventListener(evt, function(e){
				// 		e.preventDefault();
				// 		e.stopPropagation();
				// 	}.bind(this), false);
				// })
				this.$refs["dropzone"].addEventListener(evt, function(e){
					e.preventDefault();
					e.stopPropagation();
				}.bind(this), false);
			}.bind(this));
		},
		uploadExcel(file) {

			let fData = new FormData();
			fData.append("smn_excel", file);
			console.log(this.$geojson + "/etl");
			this.$axios.post(this.$geojson + "/etl", fData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then((results) => {
				this.isExcelFileUploading = false;
				this.$emit('toast', {
					"title": "Import TdB Réalisé",
					"color": "gd-green",
					"icon": "check"
				})
			})
			.catch((error) => {
				this.isExcelFileUploading = false;
				this.$emit('toast', {
					"title": "Erreur",
					"content": "Il s'est passé une erreur pendant l'import. Veuillez verifier l'état de votre fichier TdB",
					"color": "gd-red",
					"icon": "cog"
				})
			});
		}
	}
}
</script>

<style lang="scss" scoped>

#page-etl
{
	.content
	{
		width: 100%;
		display: grid;
		grid-template-columns: [main] auto [sidebar] minmax(33%, 320px);
		grid-template-rows: auto;
		gap: 16px;

		.dropzone-container
		{
				grid-column: main;
			.dropzone
			{

				height: 128px;

				cursor: pointer;

				background-color: $BG_TERTIARY;
				border-radius: 8px;
				border: 2px dashed $FG_TERTIARY;

				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				transition: all 0.1s ease;
				.dropzone-content
				{
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;

					&.loading
					{
						flex-direction: row;

						.loading-icon
						{
							margin: 16px;
							font-size: 48px;
						}
					}

					.label
					{
						font-size: 32px;
						color: $FG_TERTIARY;
						transition: all 0.1s ease;
						opacity: 0.5;
						margin: 0;

						&.small
						{
							font-size: 16px;
						}
					}
				}

				&:hover
				{
					transform: translateY(-1px);

					.label
					{
						opacity: 1;
					}
				}
			}
		}

		.informations
		{
			grid-column: sidebar;
			background-color: $BG_MAIN;
			border-radius: 8px;
			border: 1px solid lighten($color: $BG_SECONDARY, $amount: 5%);
			box-shadow: $SHD_DEFAULT;
			padding: 16px;

			.title
			{
				font-weight: 400;
				font-size: 18px;
			}
			.info-content
			{
				text-align: justify;
			}
		}
	}
}
</style>
