<template>
  <router-view class="main-content"/>
</template>

<style lang="scss">
#app
{
	background-color: $BG_MAIN;

    color: $FG_MAIN;

    font-family: Signika;
    font-weight: 300;
    overflow-x: hidden;
    height: 100vh;
    width: 100vw;
    .main-content
    {
        height: 100vh;
        width: 100vw;
		overflow: hidden;
    }

	h1, h2, h3, h4, h5
	{
		margin: 0;
		user-select: none;
	}
    p
    {
        margin: 0;
    }
}
</style>
