<template lang="html">
  	<div class="ui-button" :class="buttonClasses" ref="ui-button">
		<span class="text">
			<slot>texte</slot>
		</span>
  	</div>
</template>

<script>
export default {
	name: "Button",
	data () {
		return {
			buttonClasses : {}
		};
	},
	props: {
		type: {
			type: String,
			default: "primary"
		},
		color: {
			type: String,
			default: "white"
		}
	},
	mounted () {
		this.buttonClasses[`type-${this.type}`] = true;
		this.buttonClasses[`color-${this.color}`] = true;
	}
}
</script>

<style lang="scss" scoped>

.ui-button
{
	cursor: pointer;
	font-weight: 400;

	$BTN_MAIN: $FG_MAIN;
	$BTN_ALT: $BG_MAIN;

    user-select: none;

	background-color: $BTN_MAIN;
	color: $BTN_ALT;

	display: inline-block;
	padding: 8px 16px;
	border-radius: 8px;

	transition: all 0.1s ease;

	& + .ui-button
	{
		margin-left: 16px;
	}

	&:hover
	{
		background-color: white;
	}

	&.type-
	{
		&secondary
		{
			background-color: transparent;
			color: white;
			border: 2px solid $BTN_MAIN;
			padding: 6px 14px;

			&:hover
			{
				background-color: white;
				color: $BG_MAIN;
			}
		}

		&tertiary
		{
			background-color: transparent;
			color: $BTN_MAIN;
			text-decoration: underline;
			padding: 8px 0px;

			&:hover
			{
				color: white;
			}
		}
	}


	&.color-
	{
		&gd-blue
		{
			background: $GD_BLUE;
			color: white;
		}
		&gd-red
		{
			background: $GD_RED;
			color: white;
		}
		&gd-green
		{
			background: $GD_GREEN;
			color: white;
		}
		&gd-yellow
		{
			background: $GD_YELLOW;
			color: white;
		}

		&blue
		{
			background-color: $AC_BLUE;
			color: $FG_MAIN;

			&:hover
			{
				background-color: lighten($AC_BLUE, 5%);
			}
		}
        &wa-blue
		{
			background-color: $WA_BLUE;
			color: $FG_MAIN;

			&:hover
			{
				background-color: lighten($WA_BLUE, 5%);
			}
		}
		&red
		{
			background-color: $AC_RED;
			color: $FG_MAIN;

			&:hover
			{
				background-color: lighten($AC_RED, 5%);
			}
		}
		&green
		{
			background-color: $AC_GREEN;
			color: white;

			&:hover
			{
				background-color: lighten($AC_GREEN, 5%);
			}
		}
		&yellow
		{
			background-color: $AC_YELLOW;
			color: $BG_MAIN;

			&:hover
			{
				background-color: darken($AC_YELLOW, 10%);
			}
		}
	}

}

</style>
