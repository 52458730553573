<template lang="html">
	<header>
		  <Breadcrumbs/>
		  <h1>{{title}}</h1>
	  </header>
</template>

<script>
import Breadcrumbs from "@/components/ui/Breadcrumbs";

export default {
	components:
	{
		Breadcrumbs
	},
	props: {
		title: {
			type: String,
			default: "Title"
		}
	}
}
</script>

<style lang="scss" scoped>
header
{
	margin-bottom: 64px;

	h1
	{
		font-size: 40px;
	}
}
</style>
