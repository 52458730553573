<template lang="html">
	<div class="ui-label" :class="labelClasses">
		 <span class="texte">
		 	<slot></slot>
		 </span>
	</div>
</template>

<script>
export default {
	name: "Label",
	data () {
		return {
			labelClasses : {}
		};
	},
	props: {
		color: {
			type: String,
			default: "white"
		},
		size: {
			type: String,
			default: "small"
		}
	},
	mounted () {
		this.labelClasses[`color-${this.color}`] = true;
		this.labelClasses[`size-${this.size}`] = true;

		if (!this.$slots.default) {
			this.labelClasses[`empty`] = true;
		}
	}
}
</script>

<style lang="scss" scoped>
.ui-label
{
	display: inline-flex;

	min-height: 6px;
	min-width: 6px;
	max-height: 13px;
	background-color: $FG_MAIN;
	color: $BG_MAIN;

	font-weight: 700;
	text-transform: uppercase;
	font-size: 11px;
	letter-spacing: 1px;
	padding: 0px 6px;
	margin: 0px 3px;
	border-radius:16px;

	vertical-align: top;

	justify-content: center;
	align-items: center;

	&.empty
	{
		padding: 0;
		margin: 0px 2px;
	}

	&.color-
	{
		&blue
		{
			background-color: $AC_BLUE;
			color: $FG_MAIN;
		}
		&red
		{
			background-color: $AC_RED;
			color: $FG_MAIN;
		}
		&green
		{
			background-color: $AC_GREEN;
			color: white;
		}
	}

	&.size-
	{
		&large
		{
			max-height: 15px;
			font-size: 14px;
			padding: 1px 8px;
		}
	}
}
</style>
