<template lang="html">
	<div class="page" id="page-settings">
  		<PageHeader :title="$route.name"/>

		Cette page est vide pour l'instant, et se remplira au gré des mises a jour.
  	</div>

</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
