<template lang="html">
	<div class="page" id="page-dashboard">
		<PageHeader :title="$route.name"/>
		Cette page est vide pour l'instant, et se remplira au gré des mises a jour.
	</div>
</template>

<script>
import PageHeader from "@/components/ui/prefabs/PageHeader";

export default {
	components:
	{
		PageHeader
	},
	methods: {

	}
}
</script>

<style lang="scss" scoped>
</style>
