import { createRouter, createWebHistory } from 'vue-router'

import Login from '../views/Login.vue'
import BackOffice from '../views/BackOffice.vue'
import Settings from '../views/Pages/Settings.vue'
import Dashboard from '../views/Pages/Dashboard.vue'
import ETL from '../views/Pages/ETL.vue'
import Page404 from '../views/Page404.vue'


const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '',
    name: 'Back-Office',
    component: BackOffice,
    meta: {
        needs_auth: true
    },
	children: [
		{
			path: '',
			name: 'Dashboard',
			component: Dashboard
		},
        {
			path: 'settings',
			name: 'Paramètres',
			component: Settings
		},
		{
			path: 'etl',
			name: 'Import TdB Pilotage',
			component: ETL
		}
	]
  },
  {
      path: '/:catchAll(.*)',
      name: '404',
      component: Page404
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
console.log(router.app);
router.beforeEach((to, from, next) => {
    // Connexion a l'admin
    if (to.matched.some(record => record.meta.needs_auth)) {
        if (router.cookies.get('wa.bo.log.token') !== router.sha256(router.cookies.get('wa.bo.log.time') + "fc14e9d0423bb2b").toString()) {
            next({
                path: '/login',
            })
        }
        else {
            var time_of_connexion = Date.now().toString();
            router.cookies.set(
                "wa.bo.log.time",
                time_of_connexion,
                {
                    expires: new Date( Date.now() + 1500000 )
                }
            )
            router.cookies.set(
                "wa.bo.log.token",
                router.sha256(time_of_connexion + "fc14e9d0423bb2b").toString(),
                {
                    expires: new Date( Date.now() + 1500000 )
                }
            )
            next();
        }
    }
    else {
        next();
    }
})

const DEFAULT_TITLE = ' - Seine Maritime Numérique';
router.afterEach((to, from) => {
    document.title = to.name + DEFAULT_TITLE;
});
export default router
